import React from "react";

import HTMLReactParser from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";

import { TeamContainer } from "./Team.styles";

const Team = (props) => {
  //PROPS
  const { title, subtitle, tMember } = props;
  //PROPS

  return (
    <TeamContainer className="container container--xsmall">
      {title && HTMLReactParser(title)}
      {subtitle && HTMLReactParser(subtitle)}
      <div className="members">
        {tMember.map((member, index) => {
          return (
            <div key={index} className="member">
              <GatsbyImage
                image={member.image.localFile.childImageSharp.gatsbyImageData}
              />
              <h3>{member.name && HTMLReactParser(member.name)}</h3>
              <p>{member.mTitle && HTMLReactParser(member.mTitle)}</p>
              <div className="contacts">
                <ul>
                  {member.smedia.map((sm, index) => {
                    return (
                      <li key={index}>
                        <a
                          href={sm.sLink?.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            alt={sm.sImage?.altText}
                            src={sm.sImage?.mediaItemUrl}
                          />
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </TeamContainer>
  );
};

export default Team;
