import styled from "styled-components";

export const TeamContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
  }
  .members {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    .member {
      width: 100%;
      padding: 20px;
      .gatsby-image-wrapper {
        aspect-ratio: 1/1 !important;
        width: 100% !important;
        height: 100% !important;
      }

      h3 {
        margin-top: 10px;
      }
      p {
        margin: 0;
        margin-top: 8px;
      }
      .contacts {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      ul {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 8px;
        li {
          margin-right: 10px;
          img {
            width: 25px;
          }
        }
      }
    }
  }

  @media (min-width: 769px) {
    h1 {
      text-align: left;
    }
    .members {
      .member {
        padding: 50px;
        width: 50%;
      }
      &:nth-child(2n) {
        transform: translateY(-40px);
      }
    }
  }
`;
